import React from 'react';
import AgentHeaderNavigation from '../../agent/AgentHeaderNavigationComponent';
import AgentCommonHeader from '../../agent/AgentCommonHeaderComponent';
import AgentLeftBar from '../../agent/AgentLeftBarComponent';
import history from "../../../shared/history";
import { dateFormat,currencyFormat, getCurrentDate } from '../../../shared/commonHelper';
import moment from "moment";
class AgentBookingComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hideMsg: false,
            leftNavActive: '/booking-calendar',
            url: history.location.pathname,
            currentDate: getCurrentDate(),
            showOfferedPrice : null,	
            transactionNo : null,
            pnr : null,
            booking_status : null,
             showTCSDeclarationPopup: false,
             selectedShortTCSDeclararion:'',
             tcsDetailedDescription:'',
             dynamicData:''
           }
        }

    componentDidMount(){
        let bookingDetails = localStorage.getItem("hotel-booking-details");
        let data = {
            "booking_id" : JSON.parse(bookingDetails).booking_id,
        }
        this.props.getBookingHotelDetails(data);
        this.processTCSDeclarationData();
    }
    componentDidUpdate(prevProps) {
        const prevResponse = prevProps.tcsDeclarationResults && prevProps.tcsDeclarationResults.response;
        const currentResponse = this.props.tcsDeclarationResults && this.props.tcsDeclarationResults.response;
        if (!prevResponse && currentResponse) {
            this.processTCSDeclarationData();
        }
    }

    handleClickValue(value) {
        this.setState({ leftNavActive: value });
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }

    getHotelRatings = (hotel_rating) => {
		let ratingLi = [];
		for(let i=0; i<hotel_rating; i++) {
			ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
		}
		return ratingLi;
    }
    
    printHotelInvoice = (data) => {
        let printHotelInvoiceData = {
            "booking_id" : data.booking_id
        }
        localStorage.setItem("print-hotel-invoice", JSON.stringify(printHotelInvoiceData));
        window.open('/print-hotel-invoice', "_blank");
    }

    printHotelVoucher = (data) => {
        let printHotelVoucherData = {
            "booking_id" : data.booking_id,
            "tbo_booking_id" : data.tbo_booking_id
        }
        localStorage.setItem("print-hotel-voucher-data", JSON.stringify(printHotelVoucherData));
        window.open('/hotel-voucher', "_blank");
    }

    processTCSDeclarationData = () => {
        const { tcsDeclarationResults, accountResponse,hotelBookedDetails } = this.props;
        const res = tcsDeclarationResults && tcsDeclarationResults.response;
        const accountResponseData = accountResponse && accountResponse.myAccountResponse && accountResponse.myAccountResponse.response;
        let data = hotelBookedDetails && hotelBookedDetails.response && hotelBookedDetails.response.booking_details;
        if ((!res || !res.message || !res.message.length) || this.state.selectedShortTCSDeclararion) {
            return;
        }


        const AgentName = `${(accountResponseData && accountResponseData.first_name) || ""} ${(accountResponseData && accountResponseData.last_name) || ""}`.trim();

        const trevellerNames = [];
        const trevellerPan = [];

        data && data.hotel_room_details.map((item, index) => {
            item && item.hotel_passengers.forEach(passenger => {
                let fullName = `${passenger.title} ${passenger.first_name} ${passenger.last_name}`;
                trevellerNames.push(fullName);
                if (passenger.pan) {
                    trevellerPan.push(passenger.pan);
                }
            });
        });

        const dynamicData = {
            AGENT_NAME: AgentName,
            AGENT_ADDRESS: accountResponseData && accountResponseData.office_address,
            PAX_NAMES: trevellerNames.join(", "),
            PAN_NUMBERS: trevellerPan.join(", ").toUpperCase(),
            CURRENT_DATE: moment(getCurrentDate()).format('DD/MM/YYYY')

        };

        // Use requestAnimationFrame instead of setTimeout for better performance
        requestAnimationFrame(() => {
            this.setState({
                selectedShortTCSDeclararion: res && res.message && res.message[0].short_description,
                tcsDetailedDescription: this.replacePlaceholders(res && res.message && res.message[0].full_description, dynamicData),
                dynamicData:dynamicData
            });
        });

    };

    tripjackTCSDeclarationAtBooking = (supplierType) => {
        const data = {
            "supplier": supplierType
        }
        const successCb = (response) => {
            this.handleTCSDeclarationPopup()
        }
        const errorCb = (error) => {
        }
        this.props.hotelTripjackTCSDeclaration(data, successCb, errorCb);
    }

    handleTCSDeclarationPopup = () => {
        this.setState({ showTCSDeclarationPopup: !this.state.showTCSDeclarationPopup });
    }
    handleTcsChange = (event,modifiedDescription) => {
        this.setState({
            selectedShortTCSDeclararion: event,
            tcsDetailedDescription: modifiedDescription
        });
    }
    replacePlaceholders = (htmlContent, replacements) => {
        let modifiedContent = htmlContent;
        Object.keys(replacements).forEach((key) => {
            const regex = new RegExp(`{{${key}}}`, "g");
            modifiedContent = modifiedContent.replace(regex, replacements[key]);
        });
        return modifiedContent;
    };
    
    render() {
        const { accountResponse,productResponse } = this.props;
        let data = this.props.hotelBookedDetails.response && this.props.hotelBookedDetails.response.booking_details;
        let activeLeftNavigation  = this.state.url;
        let showInsuffient = false
        let offeredFare = 0;
        let myaccountResponse = accountResponse.myAccountResponse.response;
        let totalAccountBalance = 0;

        if (data && data.supplier_type === 'tripjack') {
            for (let i = 0; i < (data && data.hotel_room_details && data.hotel_room_details.length); i++) {
                offeredFare += (data && data.hotel_room_details && data.hotel_room_details[i] && data.hotel_room_details[i].price_modified && data.hotel_room_details[i].price_modified.published_price)
            }
        }
        
        if(myaccountResponse){
            if(parseInt(myaccountResponse.cash_balance) > 0){
                totalAccountBalance = parseInt(myaccountResponse.cash_balance) + parseInt(myaccountResponse.credit_limit);
            }else{
                totalAccountBalance = parseInt(myaccountResponse.credit_limit);
            }
        }
        
        if (data && data.supplier_type === 'tripjack') {
            if (totalAccountBalance < offeredFare) {
                showInsuffient = true;
            }
        }

        return (
            <React.Fragment>
                {/*nav-section Start*/}
                <AgentHeaderNavigation productResponseList={productResponse} {...this.props}/>
                {/*nav-section end*/}
                <section className="mid-wrapper hotel-book-container">
                    <AgentCommonHeader {...this.props} />
                    <div className="my-account-info">
                        <div className="container">
                            <div className="my-account-sec">
                                <div className="my-account-outer">
                                    <AgentLeftBar handleClickValue={this.handleClickValue.bind(this)} accountResponse={accountResponse} activeLeftNavigation={activeLeftNavigation} logoutAction={this.props.logoutAction}/>
                                    <div className="right-side">
                                        <div className="review-steps-wrap">
                                            <div className="tabs-content-wrap">
                                                <div className="step-content">
                                                { 
                                                    data ?
                                                    <React.Fragment>
                                                    {<HotelFareSummaryComponent  roomData={data.hotel_room_details} fareDetails={data.total_price} />}
                                                    <div className="review-filght-step">
                                                        {<BookingConfirmationDetailsComponent roomDetails={data} {...this.props}/>}
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"><span>Booking Details</span></div>
                                                                <div className="cols pull-right print-ticket">
                                                                    {
                                                                        data.booking_status_id === 16 &&
                                                                        <React.Fragment>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a href="/" onClick={(e) => {e.preventDefault();this.printHotelInvoice(data)}}><i className="fa fa-print" aria-hidden="true"></i>Print Invoice</a>
                                                                            { /* eslint-disable-next-line */}
                                                                            <a href="/" onClick={(e) => {e.preventDefault();this.printHotelVoucher(data)}}><i className="fa fa-print" aria-hidden="true"></i>Print Voucher</a>
                                                                        </React.Fragment>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="flight-information">
                                                                        <div className="inner-fl-info">
                                                                            <table className="fl-book-info hotel-info">
                                                                                <thead></thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td><span className="text-bold">Hotel Name : </span> {data && data.hotel_name}
                                                                                        <ul className="rating">
																								{this.getHotelRatings(data && data.star_rating)}	
																							</ul>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><span className="text-bold">Hotel Address : </span> {data && data.address_line1}<br></br> {data && data.address_line2}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="step-deatil-outer">
                                                                    <div className="flight-information">
                                                                        <div className="inner-fl-info">
                                                                            <table className="fl-book-info">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Rooms</th>
                                                                                        <th>Room Type</th>
                                                                                        <th>No. of Guests</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                { 
                                                                                    data && data.hotel_room_details.map((room, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td>{'Room '+(index+1)}</td>
                                                                                                <td className="room-inclusion">
                                                                                                    <span>{room.room_type_name+' '}</span>
                                                                                                </td>
                                                                                                <td>{room.adult_count+' Adult(s)'} {room.child_count !== 0 && ','+room.child_count+'Child(s)'}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/*------------------- Passenger details --------------------*/}
                                                        {
                                                            data &&
                                                            <PassengerDetailComponent  roomDetails={data}/>
                                                        }
                                                        {/* ------------------ Inclusions Details -------------------- */}
                                                        {
                                                            data &&
                                                            <InclusionsDetailComponent roomDetails={data} />
                                                        }
                                                        <div className="review-step">
                                                            <div className="review-step-title">
                                                                <div className="cols pull-left"><span>Cancellation Policy</span></div>
                                                            </div>
                                                            <div className="review-step-deatil">
                                                                <div className="step-deatil-outer">
                                                                    <div className="fare-rule-info">
                                                                        <div className="inner-fare-block">
                                                                        { 
                                                                            data && data.supplier_type !== 'tripjack' && data.booking_type === 'online' && data.hotel_room_details.map((room, index) => {
                                                                                let new_data = '';
                                                                                if(room.cancellation_policy === null){
                                                                                    new_data = room.cancellation_policy
                                                                                }else{
                                                                                    new_data = room.cancellation_policy.split('|');
                                                                                }
                                                                                return (
                                                                                    <ol key={index}>
                                                                                        { 
                                                                                            new_data && new_data.map((policy,indexs) => { 
                                                                                                return (<li key={indexs}>{indexs+1+'. '+policy}</li>)
                                                                                            })
                                                                                        }
                                                                                    </ol>
                                                                                )
                                                                            })
                                                                        }
                                                                        { 
                                                                            data && data.supplier_type === 'tripjack' && data.booking_type === 'online' && data.hotel_room_details.map((room, index) => {
                                                                                let new_data = '';
                                                                                if(room.cancellation_policy === ''){
                                                                                    new_data = room.cancellation_policy
                                                                                }else{
                                                                                    new_data = room.cancellation_policy;
                                                                                }
                                                                                return (
                                                                                    <ol key={index}>
                                                                                        {
                                                                                            <table className="fl-book-info">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Cancelled on or After</th>
                                                                                                        <th>Cancelled on or Before</th>
                                                                                                        <th>Cancellation Charges</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {
                                                                                                        new_data && new_data.map((Policies, cancellationKey) => {
                                                                                                            return (
                                                                                                                <tr key={cancellationKey}>
                                                                                                                    <td>{dateFormat(Policies.from_date, 'DD MMM YYYY')},{" "}{moment(Policies.from_date).format("hh:mm A")}</td>
                                                                                                                    <td>{dateFormat(Policies.to_date, 'DD MMM YYYY')},{" "}{moment(Policies.to_date).format("hh:mm A")}</td>
                                                                                                                    {Policies.charge_type === 1 && <td>Rs. {Policies.charge}</td>}
                                                                                                                    {Policies.charge_type === 2 && <td>{Policies.charge}%</td>}
                                                                                                                </tr>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </tbody>
                                                                                            </table>
                                                                                        }
                                                                                    </ol>
                                                                                )
                                                                            })
                                                                        }

                                                                        {
                                                                            data && data.booking_type === 'offline' && data.hotel_room_details.map((room, index) => {
                                                                                let new_data = room.cancellation_policy;
                                                                                return (
                                                                                    <div key={index} className="offline-policy">
                                                                                        <div  dangerouslySetInnerHTML={{ __html: new_data }} ></div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {   !data.voucher_status &&
                                                            <React.Fragment>
                                                                { /* eslint-disable-next-line */}
                                                                {showInsuffient && (
                                                                    <span className="insufficient_balance" style={{ textAlign: 'left' }}>
                                                                        Sorry, insufficient balance in your account. Booking can't proceed.
                                                                    </span>
                                                                )}
                                                                {
                                                                    data && data.supplier_type !== 'tripjack' &&
                                                                    <a href="/" className={`btn btn-orange loading-btn`}  onClick={(e) => {e.preventDefault(); this.props.generateVoucher(data)}}><span>Generate Voucher</span></a>
                                                                }
                                                                {
                                                                    data && data.supplier_type === 'tripjack' && data.booking_status_name === 'Hold' &&
                                                                    <a href="/" className={`btn btn-orange loading-btn ${showInsuffient ? 'btn-disabled' : ''}`} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        if (data && data.guest_nationality === 'IN' && data.guest_country_code !== 'IN') {
                                                                            this.tripjackTCSDeclarationAtBooking(data && data.supplier_type)
                                                                        } else {
                                                                            this.props.generateTripjackVoucherAfterHold(data, this.props.searchQuery)
                                                                        }
                                                                    }}><span>Generate Voucher</span></a>
                                                                }  
                                                                {
                                                                    (this.props.voucherDetails.loading || this.props.isPendingBookingData) &&                                               
                                                                    <div className="loader-hotel">
                                                                        <div className="loader-mid"><img src="assets/images/white-hotel-loader.gif" alt="loader" /></div>        
                                                                        <div className="overlay-loader show"></div>
                                                                    </div>
                                                                }
                                                            </React.Fragment>
                                                        }

                                                        
                                                    </div>
                                                    </React.Fragment>
                                                :<div className="loader relative-loader" style={{"display":"block"}}>
                                                        <div className="loader-content">
                                                            <div className="loader-ring blue-ring"> 
                                                                <span></span> 
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.showTCSDeclarationPopup &&
                    <BookingTCSDeclarationComponent {...this.props} {...this.state} handleTcsChange={this.handleTcsChange} dynamicData={this.state.dynamicData} replacePlaceholders={this.replacePlaceholders} handleTCSDeclarationPopup={this.handleTCSDeclarationPopup} />
                }
            </React.Fragment>
        )
    }
}
class PassengerDetailComponent extends React.Component {
    render() {
        let { roomDetails } = this.props
        let hotelRoomInfo = roomDetails && roomDetails.hotel_room_details;
        let rows = [];

		hotelRoomInfo.map((room, roomIndex) => {
            room.hotel_passengers.map((guest, guestIndex) => {
                rows.push(

                    <div className="cols" key={guestIndex+''+roomIndex}>
                        <div className="adult-title"><span>Room {roomIndex+1}</span><small>({guest.type === 'Adult'?'Adult ':'Child '}{guestIndex+1})</small></div>
                        <ul className="psg-list">
                            <li>
                                <span>Name:</span>
                                <small>{guest.title+' '+guest.first_name+' '+guest.last_name}</small>
                            </li>

                            {guest.pan && guest.type === 'Adult' &&
                                <li>
                                    <span>PAN:</span>
                                    <small>{guest.pan}</small>
                                </li>
                            }

                            { guest.type === 'Infant' &&
                                <li>
                                    <span>Age:</span>
                                    <small>{guest.age }</small>
                                </li>
                            }
                        </ul>
                    </div>
                );
                return rows;
            });
            return rows;
        });
    return (
        <div className="review-step">
            <div className="review-step-title">
                <div className="cols pull-left"> <span>Guest Details</span> </div>
                <div className="cols pull-right">  </div>
            </div>
            <div className= "review-step-deatil">
                <div className="step-deatil-outer">
                    <div className="passenger-information">
                        <div className="passenger-fl-info">
                            {rows}
                        </div>
                    </div>
                </div>
            </div>
        </div>     
    );
	}
}

/*--------- Inclusions details component ------------*/
class InclusionsDetailComponent extends React.Component { 
    render(){
        let { roomDetails } = this.props;
        let hotelRoomInfo = roomDetails && roomDetails.hotel_room_details;
        return(
                <div className="review-step">
                    <div className="review-step-title">
                        <div className="cols pull-left"><span>Inclusions</span></div>
                    </div>
                    <div className="review-step-deatil"><div className="step-deatil-outer">
                        <div className="flight-information">
                            <div className="inner-fl-info">
                                <table className="fl-book-info">
                                    <thead></thead>
                                        <tbody>
                                            { hotelRoomInfo && hotelRoomInfo.map((room, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{'Room '+(index+1)}</td>
                                                        <td>
                                                            {room.inclusions.length !== 0?<span>{room.inclusions.join(',')}</span>:'-'}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                </table>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            );
    }
}

/*--------- Hotel confirmation details component ------------*/
class BookingConfirmationDetailsComponent extends React.Component { 
    render(){
        let hotelInfo = this.props.roomDetails;
        let bookingDate = hotelInfo.booking_date;
        return(
                <div className={ hotelInfo.booking_status_id !== 16 ? "review-step hold-booking-confirm" : 'review-step'}>
                    <div className={hotelInfo.voucher_status? "review-step-deatil select-booking":'review-step-deatil'}>
                        <div className="step-deatil-outer">
                            <div className="flight-details-block">
                                <div className="flight-col">
                                    <div className="flight-head">
                                        <div className="cols">
                                        { hotelInfo.voucher_status?<span className="confirm-icon"><i className="icon icon-checked"></i></span>:'' }
                                        <span>{this.props.accountResponse && this.props.accountResponse.myAccountResponse && this.props.accountResponse.myAccountResponse.response && this.props.accountResponse.myAccountResponse.response.company_name} </span>
                                        </div>
                                        <div className="cols">
                                            <div className="voucher-info">
                                                <h5>{hotelInfo.booking_status_name}</h5>
                                                <div className="last-cancel-date">
                                                    <span className="date-text">Last Cancellation Date</span>
                                                    <span className="last-date">{dateFormat(hotelInfo.last_cancellation_date,'DD MMM YYYY')}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cols">{hotelInfo.booking_status_id === 16 ? <span className="pnr-no">Confirmation No <samp>{hotelInfo.confirmation_no}</samp></span> : <span className="pnr-no"><samp>{hotelInfo.booking_status_name}</samp></span>}</div>
                                            
                                    </div>
                                    <div className="flight-list">
                                        <div className="cols">
                                            <ul>
                                                <li>Check In Date: <span className="text-bold">{dateFormat(hotelInfo.check_in_date,'DD MMM YYYY')}</span> </li>
                                                <li>Check Out Date: <span className="text-bold">{dateFormat(hotelInfo.check_out_date,'DD MMM YYYY')}</span> </li>
                                            </ul>
                                        </div>
                                        <div className="cols">
                                            <ul>
                                                <li>Booked on: <span className="text-bold">{dateFormat(bookingDate,'DD MMM YYYY')}</span></li>
                                                <li>Reference No: <span className="text-bold">{hotelInfo.booking_ref_no}</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}


class HotelFareSummaryComponent extends React.Component {
    constructor(){
        super()
        this.state={
            showhidePopup : null,
            rateBreakUpData : null,
            showOfferedPrice  : null,
            fareSummaryPopup: false ,
        }
    }
    //Show offered price and discount
    offeredPriceDisplay=()=>{
        this.setState({
            showOfferedPrice : this.state.showOfferedPrice ? false : true
        })
    }
    //Function to fill week days values in rate breakup
    fillWeekDaysValues = (data) => {
        let output = [];
        if(data){
            for(let i = 0;i<=6;i++){
                if(data[i]){
                    output.push(<span key={i}>{data[i].amount}</span>);
                }else{
                    output.push(<span key={i}>-</span>);
                }
            }
        }
        return output;
    }

    fareSummaryClick() {
        this.setState({fareSummaryPopup: !this.state.fareSummaryPopup});
    }

    showRateBreakup = ()=>{
        //console.log(this.state.rateBreakUpData);
        return true
        // let oneRoomData = this.state.rateBreakUpData && hotelHelper.getDayRateBreakUpWeekWise(this.state.rateBreakUpData.day_rates);
        // let price = this.state.rateBreakUpData && this.state.rateBreakUpData.price;
        // return (
        //     <React.Fragment>
        //         <div className="week-summary">
        //             <div className="table-week-day">
        //                 <ul className="day-list">
        //                     <li></li>
        //                     <li>Sun</li>
        //                     <li>Mon</li>
        //                     <li>Tue</li>
        //                     <li>Wed</li>
        //                     <li>Thu</li>
        //                     <li>Fri</li>
        //                     <li>Sat</li>
        //                 </ul>
        //             </div>
        //             <div className="table-week-list">
        //                 <ul className="list-week">
        //                     {
        //                         oneRoomData && oneRoomData.map((item,key) => {
        //                             return (
        //                                 <li key={key}>
        //                                     <span className="head-week">Week {Number(key+1)}</span>
        //                                     {
        //                                         this.fillWeekDaysValues(item)
        //                                     }                                      
        //                                 </li>
        //                             )
        //                         })
        //                     }
        //                 </ul>
        //             </div>
        //         </div>    
        //         <div className="rate-summary">
        //         <div className="popup-head">
        //             <h2>Rate Summary</h2>
        //             <div className="popup-close"><span className="icon icon-cross" onClick={() => this.setState({
        //                 showhidePopup : false,
        //                 rateBreakUpData : null
        //             })}></span></div>
        //         </div>
        //         <div className="rate-price-list">
        //             <ul className="list-price">
        //             <li> <span>Currency</span> <small>{this.props.currency_code}</small> </li>
        //             <li> <span>Total</span> <small>{price && currencyFormat(price.room_price)}</small> </li>
        //             <li> <span>Tax(+)</span> <small>{price && currencyFormat(price.tax)}</small> </li>
        //             <li> <span>Other Tax(+)</span> <small>{price && currencyFormat(price.other_tax)}</small> </li>
        //             <li> <span>Extra Guest Charges(+)</span> <small>{price && currencyFormat(price.extra_guest_charge)}</small> </li>
        //             <li> <span>Service Charges (+)</span> <small>{price && currencyFormat(price.service_charge)}</small> </li>
        //             <li> <span>Other Charges (+)</span> <small>{price && currencyFormat(price.other_charges)}</small> </li>
        //             <li> <span>GST (+)</span> <small>{price && currencyFormat(Number(price.sgst) + Number(price.igst) + Number(price.cgst))}</small> </li>
        //             <li> <span>Total Price</span> <small>{price && currencyFormat(price.published_price)}</small> </li>
        //             </ul>
        //         </div>
        //     </div>  
        // </React.Fragment>
        // )
    }
    render() {
         return (
            <React.Fragment>
                <div className="pull-right hotel-fare-summary">{ /* eslint-disable-next-line */} 
                    <a href="/" className="btn btn-sky-blue fare-summmary-btn" onClick={(e)=>{e.preventDefault();this.fareSummaryClick()}}>{(this.state.fareSummaryPopup === true)?'Hide ':'Show '} Fare Summary</a>
                </div>
                <div style={{display:this.state.fareSummaryPopup?'block':'none'}}>
                    <div className="title-fare">
                        <h3 className="fare-title">
                            <span className="offer-amount-icon btn btn-blue net-fare-button-round" onClick={() => this.offeredPriceDisplay()}>{this.state.showOfferedPrice ? 'Hide Net Fare ' : 'Show Net Fare '} <i><img src="assets/images/offer-icon.svg" alt="" /></i></span>
                        </h3>
                    </div>
                    <div className="fare-outer">
                        {
                        this.props.roomData && this.props.roomData.map((item,key) => {
                            return (
                                <React.Fragment key={key}>
                                    <div className="cols">
                                        <h4>
                                        <span className="pull-right link-rate">
                                        { /* eslint-disable-next-line */}
                                        {/* <a href="javascript:void(0);" onClick={() => this.setState({
                                            showhidePopup : true,
                                            rateBreakUpData : item
                                        })}>Rate Breakup</a> */}
                                        </span>
                                        <samp className="heading-title">{item.room_type_name}</samp>
                                        </h4>
                                        <ul>
                                            <li> <span className="pull-left">Rate</span> <span className="pull-right"><i className="icon icon-rupee"></i>{currencyFormat(item.price.published_price)}</span> </li>
                                            <li> <span className="pull-left">No. of Rooms</span> <span className="pull-right">1</span> </li>
                                        </ul>
                                        <div className="total-amount"> <span className="pull-left">Total ({item.price.published_price+' * '+1})</span> <span className="pull-right">{currencyFormat(item.price.published_price * 1)}</span> </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                        }
                        <div className="grand-total"> <span className="pull-left">Grand Total</span> <span className="pull-right"><i className="icon icon-rupee"></i> {this.props.fareDetails &&  currencyFormat(this.props.fareDetails.published_price)}</span> </div>
                        <div className="cols net-booking" style={{display:this.state.showOfferedPrice?'block':'none'}}>
                            <ul>
                                <li> <span className="pull-left">TDS </span> <span className="pull-right"><i className="icon icon-rupee" />{this.props.fareDetails && currencyFormat(this.props.fareDetails.tds)}</span> </li>
                                <li> <span className="pull-left">Discount(-) </span> <span className="pull-right"><i className="icon icon-rupee" />{this.props.fareDetails && currencyFormat(Number(this.props.fareDetails.agent_discount)+Number(this.props.fareDetails.discount))}</span> </li>
                            </ul>
                            <div className="grand-total"> <span className="pull-left">Net Payable Amount </span> <span className="pull-right"><i className="icon icon-rupee" /> {this.props.fareDetails && currencyFormat(this.props.fareDetails.offered_price)}</span> </div>
                        </div>
                        <div className={`popup-crad rate-summary-popup ${this.state.showhidePopup?'show':''}`}>
                        <div className="popup-outer">
                            <div className="popup-content">
                                <div className="popup-scorll">
                                    <div className="summary-popup-mid">
                                        {
                                            this.state.showhidePopup &&
                                            this.showRateBreakup()
                                        }
                                    </div>
                                    </div>
                                </div>
                            </div>
                        <div className="popup-overlay"></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

/*--------- TCS Declaration Component ------------*/
class BookingTCSDeclarationComponent extends React.Component {

    render() {
        let { hotelBookedDetails, showTCSDeclarationPopup, handleTCSDeclarationPopup, replacePlaceholders, tcsDeclarationResults, selectedShortTCSDeclararion, tcsDetailedDescription, handleTcsChange, dynamicData } = this.props;
        const res = tcsDeclarationResults && tcsDeclarationResults.response;
        let data = hotelBookedDetails.response && hotelBookedDetails.response.booking_details;
        
        return (
            <div className={`popup-crad hotel-book-confirmation ${showTCSDeclarationPopup ? 'show' : ''}`}>
                <div className="popup-outer">
                    <div className="popup-content">
                        <div className="popup-head">
                            <h2>TCS Declaration</h2>
                            <div className="popup-close"><span className="icon icon-cross" onClick={() => handleTCSDeclarationPopup(null)} /></div>
                        </div>
                        {
                            res && res.message && res.message.map((item, index) => {
                                const modifiedDescription = replacePlaceholders(item.full_description, dynamicData);
                                return (
                                    <div className={`confirmation-list-item ${selectedShortTCSDeclararion === item.short_description ? 'active-list' : ''}`} key={index}>
                                        <div className="confirmation-list-item-inner">
                                            <input
                                                type="radio"
                                                id={`desc-${index}`}
                                                name="description"
                                                value={item && item.short_description}
                                                checked={selectedShortTCSDeclararion === item.short_description}
                                                onChange={(event) => {
                                                    handleTcsChange(event.target.value, modifiedDescription);
                                                }}
                                            />
                                            <label htmlFor={`desc-${index}`}>
                                                {item.short_description}
                                            </label>
                                        </div>
                                        {
                                            selectedShortTCSDeclararion === item.short_description &&
                                            <div className='confirmation-list-item-description'
                                                dangerouslySetInnerHTML={{ __html: modifiedDescription }}
                                            />
                                        }
                                    </div>
                                )
                            })
                        }

                        <div className="popup-body popup-scorll">
                            <div className="inner-confirmation">
                                <div className="popup-footer">
                                    <button className={`btn confirmation-popup-btn btn-primary ${selectedShortTCSDeclararion === '' ? 'btn-disabled' : ''}`} onClick={() => {
                                        handleTCSDeclarationPopup()
                                        this.props.generateTripjackVoucherAfterHold(data, '', tcsDetailedDescription)
                                    }}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="popup-overlay" />
            </div>
        );
    }
}
export default AgentBookingComponent