import {
    GET_PAX_TITLE_START,
    GET_PAX_TITLE_OK,
    GET_PAX_TITLE_ERR,
    BLOCK_HOTEL_ROOM_START,
    BLOCK_HOTEL_ROOM_OK,
    BLOCK_HOTEL_ROOM_ERR,
    HOTEL_BOOKING_START,
    HOTEL_BOOKING_OK,
    HOTEL_BOOKING_ERR,
    GET_HOTEL_BOOKING_OK,
    GET_HOTEL_BOOKING_ERR,
    GET_HOTEL_BOOKING_START,
    GENERATE_VOUCHER_START,
    GENERATE_VOUCHER_OK,
    GENERATE_VOUCHER_ERR,
    EMAIL_HOTEL_VOUCHER_START,
    EMAIL_HOTEL_VOUCHER_OK,
    EMAIL_HOTEL_VOUCHER_ERR,

    HOTEL_TRIPJACK_HOTEL_REVIEW_START,
    HOTEL_TRIPJACK_HOTEL_REVIEW_OK,
    HOTEL_TRIPJACK_HOTEL_REVIEW_ERR,
    HOTEL_TRIPJACK_BOOKING_START,
    HOTEL_TRIPJACK_BOOKING_OK,
    HOTEL_TRIPJACK_BOOKING_ERR,
    HOTEL_TRIPJACK_CONFIRM_BOOK_START,
    HOTEL_TRIPJACK_CONFIRM_BOOK_OK,
    HOTEL_TRIPJACK_CONFIRM_BOOK_ERR,
    GET_TRIPJACK_HOTEL_BOOKING_ERR,
    GET_TRIPJACK_HOTEL_BOOKING_OK,
    GET_TRIPJACK_HOTEL_BOOKING_START,
    HOTEL_TRIPJACK_CHECK_STATUS_START,
    HOTEL_TRIPJACK_CHECK_STATUS_OK,
    HOTEL_TRIPJACK_CHECK_STATUS_ERR,
    HOTEL_TRIPJACK_TCS_DECLARATION_START,
    HOTEL_TRIPJACK_TCS_DECLARATION_OK,
    HOTEL_TRIPJACK_TCS_DECLARATION_ERR,
} from '../../actions/hotel/hotelBookAction';

export const initialState = {
    paxTitles: {
        error :null,
        loading: false,
        response:null
    },
    blockHotelDetails : {
        response : null,
        loading  : false,
        error    : null
    },
    hotelBookDetails : {
        response : null,
        loading  : false,
        error    : null
    },
    hotelBookedDetails : {
        response : null,
        loading  : false,
        error    : null
    },
    voucherDetails : {
        response : null,
        loading  : false,
        error    : null
    },
    emailHotelVoucher : {
        response : null,
        loading  : false,
        error    : null
    },
    hotelReview: {
        response: null,
        loading: false
    },
    statusCheckDataTripjack:{
        error :null,
        loading: false,
        response:null
    },
    tcsDeclarationResults: {
        response: null,
        loading: false,
        error: null
    }
};

export function hotelBookReducer(state = initialState, action) {
    switch (action.type) {
        // pax titles
        case GET_PAX_TITLE_START :
            return {
                ...state, 
                paxTitles : {
                    error   : '',
                    loading : true
                }
            }
        case GET_PAX_TITLE_OK :
            return {
                ...state, 
                paxTitles : {
                    error       : '',
                    loading     : false,
                    response    : action.response,
                }
            };

        case GET_PAX_TITLE_ERR :
            return {
                ...state, 
                paxTitles : {
                    error   : action.error,
                    loading : false
                }
            }
            //================================ For Hotel room block API ===================================
         case BLOCK_HOTEL_ROOM_START:
         return {
             ...state,
             blockHotelDetails : {
                 response : null,
                 loading  : true,
                 error    : null
             }
         };
 
     case BLOCK_HOTEL_ROOM_OK:
         return {
             ...state,
             blockHotelDetails : {
                 response : action.output,
                 loading  : false,
                 error    : null
             }
         };
 
     case BLOCK_HOTEL_ROOM_ERR:
         return {
             ...state,
             blockHotelDetails : {
                 response : null,
                 loading  : false,
                 error    : action.error
             }
         };
        //================================ For Hotel booking results ===================================
           case HOTEL_BOOKING_START:
            return {
                ...state,
                hotelBookDetails : {
                    response : null,
                    loading  : true,
                    error    : null
                }
            };
    
        case HOTEL_BOOKING_OK:
            return {
                ...state,
                hotelBookDetails : {
                    response : action.output,
                    loading  : false,
                    error    : null
                }
            };
    
        case HOTEL_BOOKING_ERR:
            return {
                ...state,
                hotelBookDetails : {
                    response : null,
                    loading  : false,
                    error    : action.error
                }
            };

        //================================ For get Hotel booked results ===================================
        case GET_HOTEL_BOOKING_START:
           return {
               ...state,
               hotelBookedDetails : {
                   response : null,
                   loading  : true,
                   error    : null
               }
           };
   
       case GET_HOTEL_BOOKING_OK:
           return {
               ...state,
               hotelBookedDetails : {
                   response : action.output,
                   loading  : false,
                   error    : null
               }
           };
   
       case GET_HOTEL_BOOKING_ERR:
           return {
               ...state,
               hotelBookedDetails : {
                   response : null,
                   loading  : false,
                   error    : action.error
               }
           };
         //================================ For get Hotel booked results ===================================
        case GENERATE_VOUCHER_START:
            return {
                ...state,
                voucherDetails : {
                    response : null,
                    loading  : true,
                    error    : null
                }
            };

        case GENERATE_VOUCHER_OK:
            return {
                ...state,
                voucherDetails : {
                    response : action.output,
                    loading  : false,
                    error    : null
                }
            };

        case GENERATE_VOUCHER_ERR:
            return {
                ...state,
                voucherDetails : {
                    response : null,
                    loading  : false,
                    error    : action.error
                }
            };

        //================================ For emailing hotel voucher ===================================
        case EMAIL_HOTEL_VOUCHER_START:
            return {
                ...state,
                emailHotelVoucher : {
                    response : null,
                    loading  : true,
                    error    : null
                }
            };

        case EMAIL_HOTEL_VOUCHER_OK:
            return {
                ...state,
                emailHotelVoucher : {
                    response : action.output,
                    loading  : false,
                    error    : null
                }
            };

        case EMAIL_HOTEL_VOUCHER_ERR:
            return {
                ...state,
                emailHotelVoucher : {
                    response : null,
                    loading  : false,
                    error    : action.error
                }
            };

        case HOTEL_TRIPJACK_HOTEL_REVIEW_START:
            return {
                ...state,
                blockHotelDetails: {
                    response: null,
                    loading: true,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_HOTEL_REVIEW_OK:
            return {
                ...state,
                blockHotelDetails: {
                    response: action.output,
                    loading: false,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_HOTEL_REVIEW_ERR:
            return {
                ...state,
                blockHotelDetails: {
                    response: null,
                    loading: false,
                    error: action.message
                }
            };

        //================================ For Hotel booking results ===================================
        case HOTEL_TRIPJACK_BOOKING_START:
            return {
                ...state,
                hotelBookDetails: {
                    response: null,
                    loading: true,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_BOOKING_OK:
            return {
                ...state,
                hotelBookDetails: {
                    response: action.output,
                    loading: false,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_BOOKING_ERR:
            return {
                ...state,
                hotelBookDetails: {
                    response: null,
                    loading: false,
                    error: action.error
                }
            };

        //================================ For get Hotel tripjack booked results ===================================
        case HOTEL_TRIPJACK_CONFIRM_BOOK_START:
            return {
                ...state,
                voucherDetails: {
                    response: null,
                    loading: true,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_CONFIRM_BOOK_OK:
            return {
                ...state,
                voucherDetails: {
                    response: action.output,
                    loading: false,
                    error: null
                }
            };

        case HOTEL_TRIPJACK_CONFIRM_BOOK_ERR:
            return {
                ...state,
                voucherDetails: {
                    response: null,
                    loading: false,
                    error: action.error
                }
            };

        //================================ For Hotel booking results ===================================
        case GET_TRIPJACK_HOTEL_BOOKING_START:
            return {
                ...state,
                hotelBookedDetails: {
                    response: null,
                    loading: true,
                    error: null
                }
            };

        case GET_TRIPJACK_HOTEL_BOOKING_OK:
            return {
                ...state,
                hotelBookedDetails: {
                    response: action.output,
                    loading: false,
                    error: null
                }
            };

        case GET_TRIPJACK_HOTEL_BOOKING_ERR:
            return {
                ...state,
                hotelBookedDetails: {
                    response: null,
                    loading: false,
                    error: action.error
                }
            };
            case HOTEL_TRIPJACK_CHECK_STATUS_START:
                return {
                    ...state,
                    statusCheckDataTripjack: {
                        error: '',
                        loading: true,
                        response: null
                    }
                }
            case HOTEL_TRIPJACK_CHECK_STATUS_OK:
                return {
                    ...state,
                    statusCheckDataTripjack: {
                        error: '',
                        loading: false,
                        response: action.output,
                    }
                };
    
            case HOTEL_TRIPJACK_CHECK_STATUS_ERR:
                return {
                    ...state,
                    statusCheckDataTripjack: {
                        error: action,
                        loading: false,
                        response: action.output
                    }
                };

            //============================== For Hotel booking tcs declaration results ===================================

            case HOTEL_TRIPJACK_TCS_DECLARATION_START:
                return {
                    ...state,
                    tcsDeclarationResults: {
                        response: null,
                        loading: true,
                        error: null
                    }
                };

            case HOTEL_TRIPJACK_TCS_DECLARATION_OK:
                return {
                    ...state,
                    tcsDeclarationResults: {
                        response: action.output,
                        loading: false,
                        error: null
                    }
                };

            case HOTEL_TRIPJACK_TCS_DECLARATION_ERR:
                return {
                    ...state,
                    tcsDeclarationResults: {
                        response: null,
                        loading: false,
                        error: action.error
                    }
                };

        default:
            return state;
    }
}