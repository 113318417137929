import React, { Component } from 'react';
import { addLeadingZero, currencyFormat, dateFormat } from '../../shared/commonHelper';
import GoogleMap from '../../components/hotel/HotelGoogleMapComponent';
import PerfectScrollbar from 'react-perfect-scrollbar';
import * as hotelHelper from '../../shared/hotelHelper';
import moment from "moment";
class HotelRoomsComponent extends Component {
    constructor(props) {
        super(props);
        this.scrollbarRef = React.createRef();
        this.state = {
            limit: 10,
            offeredPrice: false,
            offeredPriceHotelRooms: false,
            sortField: '',
            roomTypeSearch:"",
            sortAscOrder: false,
            currentRateBreakup: '',
            currentCancellationPolicy: '',
            hotelDetailData: null,
            sortingIcon: "icon icon-caret-top high-price",
            emailPopup: false,
            sendMailValues: [],
            includeFare: false,
            sortRoomField: null,
            hotelFilters: {
                pan_not_required: false,
                free_cancellation: false,
                inclusion: []
            },
            mealDropdown: false
        };
    }
    offeredPriceClick() {
        this.setState({ offeredPrice: !this.state.offeredPrice });
    }
    showHideEmailPopup() {
        this.setState({ emailPopup: !this.state.emailPopup });
    }
    offeredPriceHotelRooms() {
        this.setState({ offeredPriceHotelRooms: !this.state.offeredPriceHotelRooms });
    }

    getHotelRatings = (hotel_rating) => {
        let ratingLi = [];
        for (let i = 0; i < hotel_rating; i++) {
            ratingLi.push(<li key={i}><i className="icon icon-star-full" /></li>)
        }
        return ratingLi;
    }

    trackScrolling = (event) => {
        let section = document.getElementsByClassName("page-container")[0];
        let footer = document.getElementsByTagName("footer")[0];
        let pageScorllRemaining = (section.scrollHeight + footer.scrollHeight - window.pageYOffset - window.innerHeight);
        if (pageScorllRemaining < footer.scrollHeight) {
            setTimeout(function (self) {
                self.setState({
                    limit: self.state.limit + 5
                });
            }, 500, this)
        }
    }


    componentDidMount() {
        this.props.scroll_position({...this.props.track_scroll_position,ref:this.scrollbarRef})
        document.addEventListener('scroll', this.trackScrolling);

        let hotel_code = localStorage.getItem('hotel_code');
        let api_calling = localStorage.getItem('api_calling');
        let result_index = localStorage.getItem('result_index');
        let trace_id = localStorage.getItem('trace_id');
        let roomDetailsData = localStorage.getItem('roomDetailsData');
        let hotels = localStorage.getItem('hotels');
        let hotelName = localStorage.getItem('hotelName');
        // let hotel_booking_details = localStorage.getItem('hotel-booking-details');
        let supplier_type = localStorage.getItem('supplier_type');

        this.setState({
            selectedHotelName: JSON.parse(hotelName),
            traceId: trace_id !=="undefined" ? (JSON.parse(supplier_type) === 'tbo' ? JSON.parse(trace_id) : '') : ""
        })
        if (JSON.parse(api_calling) === 'both') {
            if (JSON.parse(supplier_type) === 'tripjack') {
                this.props.getTripjackHotelRoomDetails(JSON.parse(roomDetailsData));
                this.props.getTripjackHotelDetails('', JSON.parse(result_index), JSON.parse(hotel_code));
            }
            if (JSON.parse(supplier_type) === 'tbo') {
                this.props.getHotelRoomsDetails(JSON.parse(roomDetailsData));
                this.props.getHotelDetails(trace_id !== 'undefined' ? JSON.parse(trace_id) : "", JSON.parse(result_index), JSON.parse(hotel_code));
            }
            this.setState({
                showRoomDetailPopup: true,
                hotelDetailData: JSON.parse(hotels),
                roomDetails: null,
                finalPrice: null,
                finalOfferedPrice: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.trackScrolling);
    }

    // Function For sort by room details
    sortHotelRoomResults = (event, type) => {
        var div = document.querySelector('.all-rooms'),
            para = document.querySelectorAll('.all-rooms ul.single-room');
        var paraArr = [].slice.call(para).sort(function (a, b) {
            if (event.target.checked === true) {
                return parseInt(a.getAttribute('data-' + type)) <= parseInt(b.getAttribute('data-' + type)) ? 1 : -1;
            }
            else {
                return parseInt(a.getAttribute('data-' + type)) >= parseInt(b.getAttribute('data-' + type)) ? 1 : -1;
            }
        });
        paraArr.forEach(function (p) {
            div.appendChild(p);
        });
    }

    // reset room filter
    resetRoomFilter = () => {
        // document.getElementById("inclusion-1").checked = false;
        // document.getElementById("free-cancellation-1").checked = false;
        document.getElementById("price-1").checked = false;
        var div = document.querySelector('.all-rooms'),
            para = document.querySelectorAll('.all-rooms ul.single-room');
        var paraArr = [].slice.call(para).sort(function (a, b) {
            return parseInt(a.getAttribute('data-price')) >= parseInt(b.getAttribute('data-price')) ? 1 : -1;
        });
        paraArr.forEach(function (p) {
            div.appendChild(p);
        });
    }

    //function to set states for sorting
    sortHotelResults = (type) => {
        if (this.state.sortField === '' && type === 'Price') { //when clicked price for first time and this.state.sortField is not set yet then sort desc because results are already displaying in asc order by price
            this.setState({
                sortingIcon: "icon icon-caret-top low-price",
                sortAscOrder: false
            });
        } else {
            if (this.state.sortField !== type) {
                if (type === 'Rating') {
                    this.setState({
                        sortingIcon: "icon icon-caret-top low-price",
                        sortAscOrder: false
                    });
                } else {
                    this.setState({
                        sortingIcon: "icon icon-caret-top high-price",
                        sortAscOrder: true
                    });
                }
            } else {
                this.setState({ sortAscOrder: !this.state.sortAscOrder }, function () {
                    if (type === 'Rating') {
                        if (!this.state.sortAscOrder) {
                            this.setState({ sortingIcon: "icon icon-caret-top low-price" });
                        } else {
                            this.setState({ sortingIcon: "icon icon-caret-top high-price" });
                        }
                    } else {
                        if (this.state.sortAscOrder) {
                            this.setState({ sortingIcon: "icon icon-caret-top high-price" });
                        } else {
                            this.setState({ sortingIcon: "icon icon-caret-top low-price" });
                        }
                    }
                });
            }
        }

        this.setState({ sortField: type });
    }

    //function to sort passed fields as per their type
    sortByFieldType = (a, b, sortType) => {
        //if clicked column is string type then compare after conversion in lowercase otherwise directly get the difference
        if (sortType === 'string') {
            const diff = a && a.toLowerCase() && a.toLowerCase().localeCompare(b.toLowerCase());
            if (this.state.sortAscOrder) {
                return diff;
            }

            return -1 * diff;
        } else {
            const diff = a - b;
            if (this.state.sortAscOrder) {
                return diff;
            }

            return -1 * diff;
        }
    }

    //function to show/hide rate breakup for a room each day in room details modal
    showHideRateBreakup = (key, innerKey) => {
        if (this.state.currentRateBreakup !== key + '-' + innerKey) {
            this.setState({
                currentRateBreakup: key + '-' + innerKey
            });
        } else {
            this.setState({
                currentRateBreakup: null
            });
        }
    }

    //function to show/hide cancellation policy for a room in room details modal
    showHideCancellationPolicy = (key, innerKey, supplier_type, hotelCode, item) => {
        if (this.state.currentCancellationPolicy !== key + '-' + innerKey) {
            this.setState({
                currentCancellationPolicy: key + '-' + innerKey
            });
            if (supplier_type === 'tripjack') {
                let data = {
                    "hotel_code": hotelCode,
                    "option_id": item.room_id
                }
                this.props.getTripjackCancelPolicy(data)
            }
        } else {
            this.setState({
                currentCancellationPolicy: null
            });
        }


    }

    //Function to fill week days values in rate breakup
    fillWeekDaysValues = (data) => {
        let output = [];
        if (data) {
            for (let i = 0; i <= 6; i++) {
                if (data[i]) {
                    output.push(<span key={i}>{data[i].amount}</span>);
                } else {
                    output.push(<span key={i}>-</span>);
                }
            }
        }
        return output;
    }
    showRateBreakup = (data) => {
        let oneRoomData = data && hotelHelper.getDayRateBreakUpWeekWise(data);
        return (
            <div className="week-summary">
                <div className="table-week-day">
                    <ul className="day-list">
                        <li></li>
                        <li>Sun</li>
                        <li>Mon</li>
                        <li>Tue</li>
                        <li>Wed</li>
                        <li>Thu</li>
                        <li>Fri</li>
                        <li>Sat</li>
                    </ul>
                </div>
                <div className="table-week-list">
                    <ul className="list-week">
                        {
                            oneRoomData && oneRoomData.map((item, key) => {
                                return (
                                    <li key={key}>
                                        <span className="head-week">Week {Number(key + 1)}</span>
                                        {
                                            this.fillWeekDaysValues(item)
                                        }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }

    //Set email data
    emailData = (event) => {
        let sendMailValues = this.state.sendMailValues;
        if (event.target.checked === true) {
            sendMailValues.push(event.target.value);
        }
        else {
            var index = sendMailValues.indexOf(event.target.value);
            if (index > -1) {
                sendMailValues.splice(index, 1);
            }
        }
        this.setState({ sendMailValues: sendMailValues });
    }

    renderDescription = (hotelDetail) => {
        return (
            <PerfectScrollbar>
                <div className="inner-tab-content">
                    <h2>About {hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_name}</h2>
                    <div className="mid-tab-section">
                        <p><span dangerouslySetInnerHTML={{ __html: hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.description }}></span> </p>
                    </div>
                </div>
            </PerfectScrollbar>
        )
    }

    // amenities popup data
    renderamenities(hotelDetail) {
        return (
            <PerfectScrollbar>
                <div className="inner-tab-content scroll-popup">
                    <div className="mid-tab-section">
                        <div className="amenities-block">
                            <div className="amenities-list">
                                <h2>Hotel Facilities</h2>

                                <ul>
                                    {
                                        hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_facilities.map((item, key) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <li>{item}</li>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="amenities-list">
                                <h2>Hotel In and Around</h2>
                                <ul>
                                    {
                                        hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.attractions && hotelDetail.response.hotel_details.attractions.map((item, key) => {
                                            return (
                                                <React.Fragment key={key}>
                                                    <li><span dangerouslySetInnerHTML={{ __html: item.value }}></span></li>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            <div className="msg-text-red">
                                <p>* Services available on Extra Charge</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PerfectScrollbar>
        );
    }
    //Get room details according to condition 
    getAvailableRooms(item, key, keyInner, supplier_type, hotelCode, cancelPolicy, checkOutDate) {
        let liClass = '';
        let liPolicyClass = '';
        let rateBreakupStyle = 'none';
        let cancellationStyle = 'none';
        // code to manage rate breakup section show/hide as per the room we clicked on
        if (key + '-' + keyInner === this.state.currentRateBreakup) {
            liClass = 'open'; //class for slide effect
            rateBreakupStyle = 'block';
        }

        // code to manage cancellation policy section show/hide as per the room we clicked on
        if (key + '-' + keyInner === this.state.currentCancellationPolicy) {
            liPolicyClass = 'open-policy';//class for slide effect
            cancellationStyle = 'block';
        }
        return (
            <React.Fragment key={keyInner}>
                <div className={'cols-list-block ' + liClass + ' ' + liPolicyClass}>
                    <div className="cols col-type">
                        <label className="mob-head">Room Types</label>
                        <div className="layer room-det"><span>{item.room_type_name}</span></div>
                        <div className="layer room-offer">
                        </div>
                        {
                            item.room_promotion !== ''
                                ?
                                <span className="red-text">{item.room_promotion}</span>
                                : ''
                        }

                    </div>
                    <div className="cols col-inclusion">
                        <label className="mob-head">Inclusion</label>
                        {
                            supplier_type === 'tripjack' &&
                            <span>{item && item.inclusion}</span>
                        }
                        {
                            supplier_type !== 'tripjack' &&
                            item && item.inclusion && item.inclusion.map((inclusionItem, inclusionindex) => {
                                if (inclusionindex > 0) {
                                    return (
                                        <span key={inclusionindex}>,{inclusionItem}</span>
                                    )
                                } else {
                                    return (
                                        <span key={inclusionindex}>{inclusionItem}</span>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="cols col-rate">
                        <label className="mob-head">Total Rate</label>
                        <div className="layer room-price">
                            {/* <span className="w-price"><small>Fare:</small> <samp><i className="icon icon-rupee" />43,279</samp></span> */}
                            <span className="c-price"><small>Fare:</small>  <samp><i className="icon icon-rupee" />{currencyFormat(item.price_modified.published_price)}</samp></span>
                            <span className="a-price" style={{ display: (this.state.offeredPriceHotelRooms === true) ? 'block' : 'none' }}><small>Fare:</small>  <samp><i className="icon icon-rupee" />{currencyFormat(item.price_modified.offered_price)}</samp></span>
                        </div>
                        <div className="layer link-list">
                            <span className="link-anchor rate-breakup" onClick={() => this.showHideRateBreakup(key, keyInner)}>Rate Breakup</span>
                            <span className="link-anchor cancellation-policy" onClick={() => this.showHideCancellationPolicy(key, keyInner, supplier_type, hotelCode, item)}>Cancellation Policy</span>
                        </div>
                        {
                             item.cancellation_policies && item.cancellation_policies.map((cancellationItem, cancellationKey) => {
                                let cancellationDate = '';
                                if (cancellationItem.charge === 0) {
                                    cancellationDate = 'Free cancellation till: ' + dateFormat(cancellationItem.to_date, 'DD MMM YYYY');
                                }
                                return (
                                    <span key={cancellationKey} className="red-text">{cancellationDate}</span>
                                )
                            })
                        }
                        
                    </div>

                    <div className="cols-list-acc rate-break-detail" style={{ 'display': rateBreakupStyle }}>
                        <h4>Rate Breakup</h4>
                        <div className="rate-summary-wrap">
                            {
                                this.showRateBreakup(item.day_rates)
                            }
                            <div className="rate-summary rate-breakupsummary">
                                <div className="summary-title">
                                    <h3>Rate Summary</h3>
                                </div>
                                <div className="rate-price-list">
                                    <ul className="list-price">
                                        <li> <span>Currency</span> <small>{item.price.currency_code}</small> </li>
                                        <li> <span>Total</span> <small>{currencyFormat(item.price_modified.room_price)}</small> </li>
                                        <li> <span>Tax(+)</span> <small>{currencyFormat(item.price_modified.tax)}</small> </li>
                                        <li> <span>Other Tax(+)</span> <small>{currencyFormat(item.price_modified.other_tax)}</small> </li>
                                        <li> <span>Extra Guest Charges(+)</span> <small>{currencyFormat(item.price.extra_guest_charge)}</small> </li>
                                        <li> <span>Service Charges (+)</span> <small>{currencyFormat(item.price_modified.service_charge)}</small> </li>
                                        <li> <span>Other Charges (+)</span> <small>{currencyFormat(item.price_modified.other_charges)}</small> </li>
                                        <li> <span>GST (+)</span> <small>{currencyFormat(Number(item.price_modified.sgst) + Number(item.price_modified.igst) + Number(item.price_modified.cgst))}</small> </li>
                                        {/* <li> <span>No. of Rooms</span> <small>1</small> </li> */}
                                        <li> <span>Total Price</span> <small>{currencyFormat(item.price_modified.published_price)} </small> </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cols-list-acc cancellation-detail" style={{ 'display': cancellationStyle }}>
                        <h4>Cancellation Policy</h4>
                        <div className="cancellation-col">
                            <div className="thead-col-list">
                                <div className="cancel-cols"><span>Cancelled on or After</span></div>
                                <div className="cancel-cols"><span>Cancelled on or Before</span></div>
                                <div className="cancel-cols"><span>Cancellation Charges</span></div>
                            </div>
                            <div className="tbody-col-list">
                                <ul>
                                    {
                                        supplier_type !== 'tripjack' && item.cancellation_policies.map((cancellationItem, cancellationKey) => {
                                            let cancellationCharge = '';
                                            let dynamicClass = ''
                                            if (cancellationItem.charge_type === 1) {
                                                cancellationCharge = currencyFormat(cancellationItem.charge);
                                                dynamicClass = "icon icon-rupee";
                                            }
                                            else {
                                                cancellationCharge = cancellationItem.charge + '%';
                                                dynamicClass = '';
                                            }
                                            return (
                                                <li key={cancellationKey}>
                                                    <div className="cancel-cols"><span>{dateFormat(cancellationItem.from_date, 'DD MMM YYYY')},</span>{" "}<span>{moment(cancellationItem.from_date).format("hh:mm A")}</span></div>
                                                    <div className="cancel-cols"><span>{dateFormat(cancellationItem.to_date, 'DD MMM YYYY')},</span>{" "}<span>{moment(cancellationItem.to_date).format("hh:mm A")}</span></div>
                                                    <div className="cancel-cols"><span><i className={dynamicClass}></i>{cancellationCharge}</span></div>
                                                </li>
                                            )
                                        })
                                    }
                                    {
                                        supplier_type === 'tripjack' && cancelPolicy && cancelPolicy.response && cancelPolicy.response.cancellation_policies.map((cancellationItem, cancellationKey) => {
                                            let cancellationCharge = '';
                                            let dynamicClass = ''
                                            if (cancellationItem.charge_type === 1) {
                                                cancellationCharge = currencyFormat(cancellationItem.charge);
                                                dynamicClass = "icon icon-rupee";
                                            }
                                            else {
                                                cancellationCharge = cancellationItem.charge + '%';
                                                dynamicClass = '';
                                            }
                                            return (
                                                <li key={cancellationKey}>
                                                    <div className="cancel-cols"><span>{dateFormat(cancellationItem.from_date, 'DD MMM YYYY')},</span>{" "}<span>{moment(cancellationItem.from_date).format("hh:mm A")}</span></div>
                                                    <div className="cancel-cols"><span>{dateFormat(cancellationItem.to_date, 'DD MMM YYYY')},</span>{" "}<span>{moment(cancellationItem.to_date).format("hh:mm A")}</span></div>
                                                    <div className="cancel-cols"><span><i className={dynamicClass}></i>{cancellationCharge}</span></div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    // Available Room Details popup data
    renderAvailableRooms(hotelRoomDetail, filteredHotelRoomDetail) {
      let filteredResult = hotelRoomDetail && hotelRoomDetail.response.room_combinations.room_combination.map((itemOuter, key) => {
            let hotelRooms = hotelHelper.getHotelFromRoomIndex(itemOuter, filteredHotelRoomDetail); //Get rooms by room index which are in one combination
           return hotelRooms.filter((item) => item.room_type_name.toLowerCase().includes(this.state.roomTypeSearch.toLowerCase()))
        })
        let filterResultsAnother = hotelRoomDetail &&  hotelRoomDetail.response.room_combinations.room_combination.filter((itemOuter, key) => {
            let hotelRooms = hotelHelper.getHotelFromRoomIndex(itemOuter, filteredHotelRoomDetail); //Get rooms by room index which are in one combination
           return hotelRooms.filter((item) => {
                if(item.room_type_name.toLowerCase().includes(this.state.roomTypeSearch.toLowerCase())){
                    return true;
                }else{
                    return false;
                }
            }
            )
        })
        return (
            <React.Fragment>
                {
                    //If is_policy_per_stay is true then we will use room combination to fetch rooms
                    (hotelRoomDetail && hotelRoomDetail.response && hotelRoomDetail.response.room_combinations.info_source === 'FixedCombination')

                        ?
                        
                        filteredResult && filteredResult.flat().length > 0 ?  filteredResult.map((hotelRooms, key) => {
                            if (hotelRooms.length > 0) {
                                return (
                                    <ul
                                        key={key}
                                        data-price={hotelRooms[0].price_modified.offered_price}
                                        data-inclusion={hotelRooms[0].inclusion.length === 0 ? 0 : 1}
                                        data-free-cancellation={hotelRooms && hotelRooms[0] && hotelRooms[0].cancellation_policies[0] && hotelRooms[0].cancellation_policies[0].charge === 0 ? 1 : 0}
                                        data-pan-required={hotelRooms[0].is_pan_mandatory ? 1 : 0}
                                        className="single-room"
                                    >
                                        <React.Fragment>
                                            <li>
                                                <React.Fragment>
                                                    <div className="cols col-check">
                                                        <div className="radio-check">
                                                            <input type="radio" id={key} name="choose_room"
                                                                onClick={() => { this.props.getChooseRooms(hotelRooms, false) }}
                                                            />
                                                            <label htmlFor={key} />
                                                        </div>
                                                    </div>
                                                    <div className="rooms-detail-outer">
                                                        {
                                                            hotelRooms.map((item, keyInner) => {
                                                                return (
                                                                    this.getAvailableRooms(item, key, keyInner)
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </React.Fragment>
                                            </li>
                                        </React.Fragment>
                                    </ul>
                                )
                            } else {
                                return false;
                            }
                        }) : <div className="no-result-found">
                            <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                            <div className="text-no-result">
                                <h2>No rooms found</h2>
                            </div>
                        </div>
                        :

                        //else we have to use  hotel_rooms_details.
                        filterResultsAnother &&filterResultsAnother.length > 0 && filterResultsAnother.map((itemOuter, key) => {
                            return (
                                <ul key={key}>
                                    <React.Fragment>
                                        <h6>Room {Number(key) + 1}</h6>
                                        {
                                            filteredHotelRoomDetail.map((itemInner, keyInner) => {

                                                return (
                                                    <React.Fragment key={keyInner}>
                                                        <li
                                                            data-price={itemInner.price_modified.published_price}
                                                            data-inclusion={itemInner.inclusion.length === 0 ? 0 : 1}
                                                            data-free-cancellation={itemInner.cancellation_policies[0].charge === 0 ? 1 : 0}
                                                            data-pan-required={itemInner.is_pan_mandatory ? 1 : 0}
                                                            className="single-room">
                                                            <React.Fragment>
                                                                <div className="cols col-check">
                                                                    <div className="radio-check">
                                                                        <input type="radio" id={key + '' + keyInner} name={"choose_room" + key}
                                                                            onChange={() => {
                                                                                this.props.getChooseRooms(itemInner, true, key)
                                                                            }}
                                                                        />
                                                                        <label htmlFor={key + '' + keyInner} />
                                                                    </div>
                                                                </div>
                                                                <div className="rooms-detail-outer">
                                                                    {
                                                                        this.getAvailableRooms(itemInner, key, keyInner)
                                                                    }
                                                                </div>
                                                            </React.Fragment>
                                                        </li>
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                </ul>
                            )
                        })
                }
            </React.Fragment>
        );
    }

    // Hotel Details popup data
    renderHotelDetail(hotelDetail) {
        return (
            <PerfectScrollbar>
                <div className="inner-tab-content scroll-popup">
                    <div className="amenities-list">
                        <h2>About {hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_name}</h2>
                        <div className="mid-tab-section">
                            <p><span dangerouslySetInnerHTML={{ __html: hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.description }}></span> </p>
                        </div>
                    </div>
                    <p></p>

                    <div className="amenities-block">
                        <div className="amenities-list">
                            <h2>Hotel Facilities</h2>
                            <ul>
                                {
                                    hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.hotel_facilities && hotelDetail.response.hotel_details.hotel_facilities.length > 0 && hotelDetail.response.hotel_details.hotel_facilities.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <li>{item}</li>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="amenities-list">
                            <h2>Hotel In and Around</h2>
                            <ul>
                                {
                                    hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.attractions && hotelDetail.response.hotel_details.attractions.map((item, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <li><span dangerouslySetInnerHTML={{ __html: item.value }}></span></li>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="msg-text-red">
                            <p>* Services available on Extra Charge</p>
                        </div>
                    </div>
                </div>
            </PerfectScrollbar>
        );
    }

    // Hotel Image Gallery popup data
    renderImageGallery(hotelDetail) {
        return (
            <PerfectScrollbar>
                <ul className="thumb-img">
                    {
                        hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.images && hotelDetail.response.hotel_details.images.map((item, key) => {
                            return (
                                <React.Fragment key={key}>
                                    { /* eslint-disable-next-line */}
                                    <li onClick={(e) =>{e.preventDefault(); this.props.getGalleryImages(item.url ? item.url : item)}}><a href="/"><img src={item.url ? item.url : item} alt="" /></a></li>
                                </React.Fragment>
                            )
                        })
                    }
                </ul>
            </PerfectScrollbar>
        );
    }
    //Send itineraries mails
    sendMail = () => {
        this.props.sendItinerariesMail(this.state.sendMailAddress, this.state.sendMailValues, this.state.includeFare);
    }

    setRoomFilters = (e, key, subKey) => {
        let filters = this.state.hotelFilters;
        if (e.target.checked && subKey === '') {
            filters[key] = true;
        } else if (e.target.checked && subKey !== '') {
            let arrayValue = filters[key];
            arrayValue.push(subKey);
            filters[key] = arrayValue;
        } else if (!e.target.checked && subKey !== '') {
            let arrayValue = filters[key];
            const index = arrayValue.indexOf(subKey);
            arrayValue.splice(index, 1);
            filters[key] = arrayValue;
        } else {
            filters[key] = false;
        }
        this.setState({ hotelFilters: filters })
    }

    openMealBox = () => {
        let mealDropdown = this.state.mealDropdown;
        if (mealDropdown) {
            this.setState({
                mealDropdown: false
            });
        } else {
            this.setState({
                mealDropdown: true
            });
        }
    }

    renderTripjackAvailableRooms(hotelRoomDetail, filteredHotelRoomDetail, hotelCode, cancelPolicy, checkOutDate) {

        let filteredResult = filteredHotelRoomDetail && filteredHotelRoomDetail.filter((item) => {
            let filteredItem = item && item.room_combination.find((item_filtered) => item_filtered && item_filtered.room_type_name && item_filtered.room_type_name.toLowerCase() && item_filtered.room_type_name.toLowerCase().includes(this.state.roomTypeSearch.toLocaleLowerCase()))
            if (filteredItem) {
                return item && item.room_combination;
            }
        })

        return (
            <React.Fragment>

                {
                    filteredResult && filteredResult.flat().length > 0 ? filteredResult.map((item, key) => {
                        return (
                            // item && item.room_combination.map((item2, key2) => {
                            // 	return (
                           item && item.room_combination && item.room_combination.length > 0 &&
                            <ul
                                key={key}
                                //  data-price={item && item.room_combination && item.room_combination[0].price_modified && (item.room_combination[0].price_modified.offered_price || item.room_combination[1].price_modified.offered_price || item.room_combination[2].price_modified.offered_price||item.room_combination[3].price_modified.offered_price||item.room_combination[4].price_modified.offered_price||item.room_combination[5].price_modified.offered_price)} 
                                //  data-inclusion={item && item.room_combination.inclusion && (item.room_combination[0].inclusion.length === 0 || item.room_combination[1].inclusion.length === 0|| item.room_combination[2].inclusion.length === 0|| item.room_combination[3].inclusion.length === 0|| item.room_combination[4].inclusion.length === 0|| item.room_combination[5].inclusion.length === 0)?0:1}
                                //  data-free-cancellation={item && item.room_combination && item.room_combination[0].cancellation_policies[0] && (item.room_combination[0].cancellation_policies[0].charge === 0 || item.room_combination[1].cancellation_policies[0].charge === 0|| item.room_combination[2].cancellation_policies[0].charge === 0|| item.room_combination[3].cancellation_policies[0].charge === 0|| item.room_combination[4].cancellation_policies[0].charge === 0|| item.room_combination[5].cancellation_policies[0].charge === 0)?1:0}
                                //  data-pan-required={item && item.room_combination && (item.room_combination[0].is_pan_mandatory || item.room_combination[1].is_pan_mandatory|| item.room_combination[2].is_pan_mandatory|| item.room_combination[3].is_pan_mandatory|| item.room_combination[4].is_pan_mandatory|| item.room_combination[5].is_pan_mandatory) ? 1 : 0}
                                className="single-room"
                            >
                                <React.Fragment>
                                    <li>
                                        <React.Fragment>
                                            <div className="cols col-check">
                                                <div className="radio-check">
                                                    <input type="radio" id={key} name="choose_room"
                                                        onClick={() => { this.props.getChooseRooms((item), false) }}
                                                    />
                                                    <label htmlFor={key} />
                                                </div>
                                            </div>
                                            <div className="rooms-detail-outer">
                                                {
                                                       item && item.room_combination && item.room_combination.length > 0 && item.room_combination.map((item1, keyInner1) => {
                                                        return (
                                                            this.getAvailableRooms(item1, key, keyInner1, (hotelRoomDetail.response.supplier_type), hotelCode, cancelPolicy, checkOutDate)
                                                        )
                                                    })
                                                }
                                            </div>
                                        </React.Fragment>
                                    </li>
                                </React.Fragment>
                            </ul>
                            // 	)
                            // })
                        )

                    }) :
                        <div className="no-result-found">
                            <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                            <div className="text-no-result">
                                <h2>No rooms found</h2>
                            </div>
                        </div>
                }
            </React.Fragment>
        );

    }

    handleRoomTypeSearch=(e)=>{
        this.setState({roomTypeSearch:e.target.value})
    }

    render() {
        if(this.props.track_scroll_position && this.props.track_scroll_position.BTT){
            const curr = this.scrollbarRef
            if (curr) {
                curr.scrollTop = 0
            }
            
        }
        const hotelSearchResult = this.props.hotelSearchResult && this.props.hotelSearchResult.hotel_results && hotelHelper.filterResults(this.props.hotelAdvanceSearchFilter, this.props.hotelSearchResult.hotel_results);
        const {searchQuery, hotelRoomDetail, hotelDetail,galleryImageUrl, activeTab,roomDetails, cancelPolicy } = this.props;
        let checkInDate = this.props.searchQuery && this.props.searchQuery.check_in_date.split('/')[2] + '-' + this.props.searchQuery.check_in_date.split('/')[1] + '-' + this.props.searchQuery.check_in_date.split('/')[0];
        let checkOutDate = this.props.searchQuery && this.props.searchQuery.check_out_date.split('/')[2] + '-' + this.props.searchQuery.check_out_date.split('/')[1] + '-' + this.props.searchQuery.check_out_date.split('/')[0];
        let selectedRoomPrice = this.props.finalPrice;
        let selectedRoomOfferedPrice = this.props.finalOfferedPrice;
        let selectedImageUrl = '';
        if (galleryImageUrl === null) {
            selectedImageUrl = hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.images && hotelDetail.response.hotel_details.images[0];
        }
        else {
            selectedImageUrl = galleryImageUrl;
        }
        //call sorting function as per the clicked field
        if (this.state.sortField && hotelSearchResult) {

            let sortChoice = this.state.sortField

            switch (sortChoice) {
                case 'Name':
                    hotelSearchResult.sort((a, b) => this.sortByFieldType(a.hotel_name, b.hotel_name, 'string'));
                    break;
                case 'Price':
                    hotelSearchResult.sort((a, b) => this.sortByFieldType(a.price.offered_price_roundedoff, b.price.offered_price_roundedoff, 'number'));
                    break;
                case 'Rating':
                    hotelSearchResult.sort((a, b) => this.sortByFieldType(a.star_rating, b.star_rating, 'number'));
                    break;
                case 'HotDeals':
                    hotelSearchResult.sort((a, b) => this.sortByFieldType(a.hotel_promotion, b.hotel_promotion, 'string'));
                    break;
                default:
                    hotelSearchResult.sort((a, b) => this.sortByFieldType(a.price.offered_price_roundedoff, b.price.offered_price_roundedoff, 'number'));
                    break;
            }
        }
        //let hotelFilterRooms = this.props.hotelRoomDetail;
        let filteredHotelRoomDetail = this.props.hotelRoomDetail.response && this.props.hotelRoomDetail.response.hotel_rooms_details && hotelHelper.filterHotelDetails(this.props.hotelRoomDetail.response.hotel_rooms_details, this.state.hotelFilters, this.props.hotelRoomDetail.response.supplier_type);
        let hotelCode = hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details && hotelDetail.response.hotel_details.hotel_code;
        let hotel_address=JSON.parse(localStorage.getItem('hotels')) && JSON.parse(localStorage.getItem('hotels')).hotel_address
        return (
            <section className="mid-wrapper inner-mid-section">
                <div className="container">
                    <div className="search-result-section book-room-result-section">
                        <div className="popup-mid book-room-content">
                            <div className="popup-tophead">
                                <div className="popup-title " id='popup-title-display'>
                                    <h3>{this.state.selectedHotelName}</h3>
                                    <ul className="rating">
                                        {this.getHotelRatings(hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.star_rating)}
                                    </ul>
                                </div>
                                <div style={{fontSize:'13px',fontWeight:500}}>
                                            <span className="text-span">{hotel_address && hotel_address.adr ? (hotel_address.adr + ", ") :''}</span>
                                            <span className="text-span">{hotel_address && hotel_address && hotel_address.city && hotel_address.city.name ? (hotel_address.city.name + ", "):''}</span>
                                            <span className="text-span">{hotel_address && hotel_address && hotel_address.state && hotel_address.state.name ? (hotel_address.state.name + ", ") :''}</span>
                                            <span className="text-span">{hotel_address && hotel_address && hotel_address.postalCode ? (hotel_address.postalCode + ", "):''}</span>
                                            <span className="text-span">{hotel_address && hotel_address && hotel_address.country && hotel_address.country.name ? hotel_address.country.name :''}</span>
                                </div>
                                <div className="room-info">
                                    <div className="pull-left">
                                        <div className="room-check-info"><small>Check-In and Check-Out:</small> <span>{dateFormat(checkInDate, 'DD MMM YYYY, FDAY')} - {dateFormat(checkOutDate, 'DD MMM YYYY, FDAY')}</span></div>
                                        <div className="room-check-info"><small>Nights:</small> <span>{searchQuery && addLeadingZero(searchQuery.no_of_nights)} Night</span></div>
                                    </div>
                                    {
                                        selectedRoomPrice &&
                                        <div className="pull-right">
                                            <div className="check-price">
                                                <span><i className="icon icon-rupee" />{currencyFormat(selectedRoomPrice)}</span>
                                                <span className="offered-final-price" style={{ display: (this.state.offeredPriceHotelRooms === true) ? 'block' : 'none' }}><i className="icon icon-rupee" />{currencyFormat(selectedRoomOfferedPrice)}</span>
                                                <small>Lowest Total Rate inc. Taxes</small>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="popup-mid-body">
                                <div className="popup-mid-body">
                                    <div className="popup-tab-container">
                                        <div className="popuptab-block">
                                            <div className="popup-tab-nav">
                                                <ul>
                                                    <li className={(activeTab === "available-rooms") ? "active" : ""} onClick={this.props.handleHotelDetailsTab.bind(this, 'available-rooms')}> <span className="anchor" title="#available-rooms">Available Rooms</span> </li>
                                                    <li className={(activeTab === "hotel-details") ? "active" : ""} onClick={this.props.handleHotelDetailsTab.bind(this, 'hotel-details')}> <span className="anchor" title="#hotel-details">Hotel Details</span> </li>
                                                    <li className={(activeTab === "image-gallery") ? "active" : ""} onClick={this.props.handleHotelDetailsTab.bind(this, 'image-gallery')}> <span className="anchor" title="#image-gallery">Image Gallery</span> </li>
                                                    <li className={(activeTab === "hotel-map") ? "active" : ""} onClick={this.props.handleHotelDetailsTab.bind(this, 'hotel-map')}> <span className="anchor" title="#hotel-map">Hotel Map</span> </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            hotelRoomDetail && hotelRoomDetail.loading
                                                ?
                                                <div className="loader relative-loader" style={{ display: 'block' }}>
                                                    <div className="loader-content">
                                                        <div className="loader-ring blue-ring"> <span></span> </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="popup-tab-content">
                                                    <div className="pop-tab-contener avilable-tab-block" id="available-rooms" style={{ display: (activeTab === 'available-rooms') ? 'block' : 'none' }}>
                                                        <div className="inner-tab-content scroll-popup">
                                                            <h2 className="head-title">About {hotelDetail && hotelDetail.response && hotelDetail.response.hotel_details.hotel_name}</h2>
                                                            
                                                           
                                                            <div className="sorting-checkbox">
                                                                <span className="sort-by"> Sort By:</span>
                                                                {/* <div className="check-term">
																<small className="checkbox"><input id="inclusion-1"  type="radio" name="sorting_room"  onClick={(e)=>this.sortHotelRoomResults(e,'inclusion')}/><label></label></small>
																<label htmlFor="inclusion-1">Inclusion</label>
															</div>
															<div className="check-term">
																<small className="checkbox"><input id="free-cancellation-1"  type="radio" name="sorting_room"  onClick={(e)=>this.sortHotelRoomResults(e,'free-cancellation')}/><label></label></small>
																<label htmlFor="free-cancellation-1">Free Cancellation</label>
															</div> */}
                                                                <div className="check-term">
                                                                    <small className="checkbox"><input id="price-1" type="radio" name="sorting_room" onClick={(e) => this.sortHotelRoomResults(e, 'price')} /><label></label></small>
                                                                    <label htmlFor="price-1">Price</label>
                                                                </div>
                                                                { /* eslint-disable-next-line */}
                                                                <a href="/" className="reset-room-filter"><span className="icon icon-refresh" onClick={(e) =>{e.preventDefault(); this.resetRoomFilter()}}></span></a>
                                                            </div>
                                                            <div className="mid-tab-section">
                                                                <div className="filters-block">
                                                                <div className="left-block head-search-box">
                                                                    <span className="search-label">Search By:</span>
                                                                    <span className="search-input">  
                                                                        <input type="text" placeholder='Search Room Type' value={this.roomTypeSearch} onChange={(e)=>this.handleRoomTypeSearch(e)}/>
                                                                    </span>
                                                                </div>
                                                                <div className="sorting-checkbox">
                                                                    <span className="sort-by"> Filter By:</span>
                                                                    <div className="check-term">
                                                                        <small className="checkbox"><input id="pan_not_required" type="checkbox" name="pan_not_required" onClick={(e) => this.setRoomFilters(e, 'pan_not_required', '')} /><label></label></small>
                                                                        <label htmlFor="pan_not_required">PAN Not Required</label>
                                                                    </div>
                                                                    <div className="check-term">
                                                                        <small className="checkbox"><input id="free_cancellation" type="checkbox" name="free_cancellation" onClick={(e) => this.setRoomFilters(e, 'free_cancellation', '')} /><label></label></small>
                                                                        <label htmlFor="free_cancellation">Free Cancellation</label>
                                                                    </div>
                                                                    <div className="check-term">
                                                                        <div className="multiselect">
                                                                            <div className="selectBox" onClick={this.openMealBox}>Meal(s)</div>
                                                                            <div id="checkboxes" style={{ display: this.state.mealDropdown ? 'block' : 'none' }}>
                                                                                <label htmlFor="breakfast">
                                                                                    <input id="breakfast" type="checkbox" name="breakfast" onClick={(e) => this.setRoomFilters(e, 'inclusion', 'breakfast')} />Breakfast</label>
                                                                                <label htmlFor="HALF BOARD">
                                                                                    <input id="half_board" type="checkbox" name="half_board" onClick={(e) => this.setRoomFilters(e, 'inclusion', 'half_board')} />Half Board</label>
                                                                                <label htmlFor="full_board">
                                                                                    <input id="full_board" type="checkbox" name="full_board" onClick={(e) => this.setRoomFilters(e, 'inclusion', 'full_board')} />Full Board</label>
                                                                                {
                                                                                    hotelRoomDetail && hotelRoomDetail.response && hotelRoomDetail.response.supplier_type === 'tripjack' &&
                                                                                    <label htmlFor="room_only">
                                                                                        <input id="room_only" type="checkbox" name="room_only" onClick={(e) => this.setRoomFilters(e, 'inclusion', 'room_only')} />Room Only</label>
                                                                                }
                                                                                {
                                                                                    hotelRoomDetail && hotelRoomDetail.response && hotelRoomDetail.response.supplier_type === 'tripjack' &&
                                                                                    <label htmlFor="all_inclusive">
                                                                                        <input id="all_inclusive" type="checkbox" name="all_inclusive" onClick={(e) => this.setRoomFilters(e, 'inclusion', 'all_inclusive')} />All Inclusive</label>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                                <div className="room-select-info">
                                                                    <div className="select-head room-select-info-head">
                                                                        <h2>Select your rooms group  <span className="btn btn-blue net-fare-button" onClick={this.offeredPriceHotelRooms.bind(this)}>{this.state.offeredPriceHotelRooms ? 'Hide Net Fare ' : 'Show Net Fare '}<img src="assets/images/offer-icon.svg" alt="offer-icon" /></span> </h2>
                                                                        {roomDetails &&
                                                                            <div className="popup-bottom-button text-right">
                                                                                <button className="btn btn-yellow" onClick={() => this.props.startBooking(this.state.hotelDetailData,(this.state.traceId))}>Continue</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    {
                                                                        hotelRoomDetail && hotelRoomDetail.response && filteredHotelRoomDetail.length > 0
                                                                            ?
                                                                            <div className="select-pay-info">
                                                                                <div className="inner-col-block">
                                                                                    <div className="room-col thead-col">
                                                                                        <div className="cols col-check" />
                                                                                        <div className="rooms-detail-outer">
                                                                                            <div className="cols col-type"><span>Room Types</span></div>
                                                                                            <div className="cols col-inclusion"><span>Inclusion</span></div>
                                                                                            <div className="cols col-rate"><span> Total Rate</span></div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <PerfectScrollbar containerRef={el => (this.scrollbarRef = el)} onScrollY={(container) =>
                                                                                       {
                                                                                        this.props.scroll_position({...this.props.track_scroll_position,roomContainer:container.scrollTop}) 
                                                                                        if(this.props.track_scroll_position.roomContainer < 50){
                                                                                            this.props.scroll_position({...this.props.track_scroll_position,BTT:false}) 
                                                                                        }
                                                                                    } 
                                                                                    } className="room-col tbody-col all-rooms">
                                                                                        {
                                                                                            hotelRoomDetail.response.supplier_type !== 'tripjack' &&
                                                                                            this.renderAvailableRooms(hotelRoomDetail, filteredHotelRoomDetail)
                                                                                        }
                                                                                        {
                                                                                            hotelRoomDetail.response.supplier_type === 'tripjack' &&
                                                                                            this.renderTripjackAvailableRooms(hotelRoomDetail, filteredHotelRoomDetail, hotelCode, cancelPolicy, checkOutDate)
                                                                                        }
                                                                                    </PerfectScrollbar>
                                                                                </div>
                                                                            </div>
                                                                            : <div className="no-result-found">
                                                                                <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                                                                                <div className="text-no-result">
                                                                                    <h2>No rooms found</h2>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="pop-tab-contener" id="hotel-details" style={{ display: (activeTab === 'hotel-details') ? 'block' : 'none' }}>
                                                        {
                                                            hotelDetail && hotelDetail.response
                                                                ?
                                                                this.renderHotelDetail(hotelDetail)
                                                                : <div className="no-result-found">
                                                                    <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                                                                    <div className="text-no-result">
                                                                        <h2>No hotel deatils found</h2>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="pop-tab-contener" id="image-gallery" style={{ display: (activeTab === 'image-gallery') ? 'block' : 'none' }}>
                                                        {
                                                            hotelDetail && hotelDetail.response
                                                                ?
                                                                <div className="inner-tab-content scroll-popup">
                                                                    <h2>Photos</h2>
                                                                    <div className="mid-tab-section">
                                                                        <div className="image-gallery-slider">
                                                                            <div className="inner-gallery">
                                                                                <div className="lg-image">
                                                                                    {
                                                                                        selectedImageUrl &&
                                                                                        <img src={selectedImageUrl.url ? selectedImageUrl.url : selectedImageUrl} alt="" />
                                                                                        // <ReactImageMagnify {...{
                                                                                        // 	smallImage: {
                                                                                        // 		isFluidWidth: true,
                                                                                        // 		src: selectedImageUrl
                                                                                        // 	},
                                                                                        // 	largeImage: {
                                                                                        // 		src: selectedImageUrl,
                                                                                        // 		width: 800,
                                                                                        // 		height: 900
                                                                                        // 	}
                                                                                        // }} />
                                                                                    }
                                                                                </div>
                                                                                <div className="thumbs-block">
                                                                                    {
                                                                                        this.renderImageGallery(hotelDetail)
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <div className="no-result-found">
                                                                    <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                                                                    <div className="text-no-result">
                                                                        <h2>No images found</h2>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className="pop-tab-contener" id="hotel-map" style={{ display: (activeTab === 'hotel-map') ? 'block' : 'none' }}>
                                                        {
                                                            hotelDetail && hotelDetail.response
                                                                ?
                                                                <div className="inner-tab-content scroll-popup">
                                                                    {/*<h2>About Sadaf Hotel</h2>*/}
                                                                    <div className="mid-tab-section">
                                                                        <div className="map-block">
                                                                            {
                                                                                hotelDetail && hotelDetail.response &&
                                                                                <GoogleMap googleMapData={hotelDetail.response} />
                                                                            }
                                                                            {/* <img src="assets/images/map.png" />  */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : <div className="no-result-found">
                                                                    <figure> <img src="assets/images/no-result.svg" alt="" /> </figure>
                                                                    <div className="text-no-result">
                                                                        <h2>No map found</h2>
                                                                    </div>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default HotelRoomsComponent;