import React, { Component } from 'react';
import Calendar from '../../components/agent/AgentCalendarComponent';
import { connect } from 'react-redux'
import { getCalendarDetail ,myAccountAction,updateTicket, emailTicket,cancelRefund,emailInvoice, emailOfflineTicket, emailOfflineInvoice,cancelHotelRefund, getBookingDetails, emailFlightCreditNote, emailHotelCreditNote, bookingInfoAction, releasePnr, releaseTripJack} from '../../actions/agentAction';
import Booking from '../../components/agent/AgentBookingComponent';
import HotelBooking from '../../components/agent/hotel/AgentHotelBookingComponent';
import {bookTicket, tripJackBookConfirm, tripJackBookDetails, validateTripJackTicket, riyaBookConfirm, riyaBookDetails} from "../../actions/flight/flightBookTicketAction";
import { logoutAction } from '../../actions/shared/sessionAction';
import { getProductAccess,isBookingPending} from '../../actions/shared/sharedAction';
import PrintCreditNote from '../../components/agent/PrintCreditNote';
import PrintHotelCreditNote from '../../components/agent/hotel/HotelPrintCreditNote';
import PrintTicketContainer from './PrintTicketContainer';
import PrintInvoiceContainer from './PrintInvoiceContainer';
import { generateConfirmBook, generateVoucher, getBookingHotelDetails,getTripjackBookingHotelDetails, hotelTripjackTCSDeclaration } from '../../actions/hotel/hotelBookAction';
import ReceiptVoucherComponent from '../../components/agent/deposits/ReceiptVoucherComponent';
import { getAirIQFlightFareRuleDetails, geteflyFlightFareRuleDetails, getGoflyFlightFareRuleDetails, getRiyaFlightFareRuleDetails, getSatkarFlightFareRuleDetails, getTripJackFlightFareRuleDetails } from '../../actions/flight/flightSearchDetailsAction';

class AgentCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
        };
    }
    componentDidMount() {
        if(window.location.pathname !== "/print-ticket"){
            this.props.getProductAccess();
        }
    }

    confirmBooking = (data) => {
        let confirm_booking = {
            "trace_id": data.details.trace_id,
            "pnr": this.props.location.state.data.pnr,
            "tbo_booking_id": parseInt(this.props.location.state.data.transaction_no),
            "booking_id": parseInt(data.booking_id) ,
            "agent_discount" : data.details.agent_discount,
            "agent_discount_type" : data.details.agent_discount_type,
            "offered_fare":  Number(data.details.fare.total_fare),
            "service_charge" : Number(data.details.fare.service_charge),
            "cgst" : Number(data.details.fare.cgst),
            "igst" : Number(data.details.fare.igst),
            "sgst" : Number(data.details.fare.sgst),
            "passengers" : data.details.passengers,
            "category_name" : this.props.bookingDetails.response && this.props.bookingDetails.response.flight_itinerary.is_domestic ? 'Domestic' : 'International',
            ...((data.details.booking_from === "tbo") && this.props.bookingDetails.response && this.props.bookingDetails.response.flight_itinerary && {
            "airline_remark": this.props.bookingDetails.response.flight_itinerary.airline_remark
        })
        }
        const successCb = (response) => {
            this.props.myAccountAction();
            this.props.history.push({
                pathname:"/booking-confirm",
                state:{
                    data: response,
                    searchQuery : this.props.searchQuery,
                    bookingType:'confirm'
                 }
               });
        }
        const errorCb = (response) => {
            this.bookingError(response);
       } 
        this.props.bookTicket(confirm_booking,successCb,errorCb);
    }

     // booking error page
     bookingError = (response) =>{
        this.props.history.push({
            pathname:"/booking-error",
            state:{
                page: 'agent',
                response: response.message,
                searchQuery : this.props.searchQuery,
                productResponse : this.props.productResponse,
                accountResponse : this.props.accountResponse,
                agentProductAccess : this.props.agentProductAccess
            }
        });
    }

    generateVoucher=(data)=>{
        let bookingData = {
            "tbo_booking_id": data.transaction_no,
            "booking_id": data.booking_id
        }
        const errorCb = (response) => {
            this.bookingError(response);
        } 
        const successCb = (response) => {
            let bookingDetails = localStorage.getItem("hotel-booking-details");
            let data = {
                "booking_id" : JSON.parse(bookingDetails).booking_id,
            }
            this.props.getBookingHotelDetails(data);
            this.props.myAccountAction();
        }
        this.props.generateVoucher(bookingData,successCb,errorCb);
    }

    generateTripjackVoucherAfterHold = (response, searchQuery,tcsDetailedDescription) => {
        let bookHotelData = {
            "tripjack_bookingId": response.tripjack_booking_id,
            "total_price": {
                "offered_price": response.total_price.offered_price
            },
            "booking_id": response.booking_id,
            "hotel_rooms_details" : response.hotel_room_details,
             "tcs_declaration" : tcsDetailedDescription
        }

        const errorCb = (response) => {
            this.bookingError(response);
        }
        const bookingDetailSuccessCb = (data) => {
            this.props.history.push({
                pathname: "/hotel-booking-confirm",
                state: {
                    bookingType: 'confirm',
                    response: response,
                    booking_value: 'Vouchered',
                    hotelBookedDetails: data
                }
            });
            this.props.myAccountAction();
        }
        const successCb = () => {
            let bookHotelData = {
                "tripjack_bookingId": response.tripjack_booking_id,
                "booking_id": response.booking_id
            }
            this.props.getTripjackBookingHotelDetails(bookHotelData, bookingDetailSuccessCb, errorCb);
        }
        this.props.generateConfirmBook(bookHotelData, successCb, errorCb);
    }

    render() {
        return (
            <div>
                {
                    this.props.type === 'calendar' &&
                        <Calendar {...this.props}/>
                }
                {
                    this.props.type === 'booking' &&
                        <Booking {...this.props} confirmBooking={this.confirmBooking}/>
                }
                {
                    this.props.type === 'hotel-booking' &&
                        <HotelBooking {...this.props} generateVoucher={this.generateVoucher} generateTripjackVoucherAfterHold={this.generateTripjackVoucherAfterHold}/>
                }
                {
                    this.props.type === 'print-ticket' &&
                        <PrintTicketContainer {...this.props}/>
                }
                {
                    this.props.type === 'print-invoice' &&
                        <PrintInvoiceContainer {...this.props}/>
                }
                {
                    this.props.type === 'print-credit-note' &&
                        <PrintCreditNote {...this.props}/>
                }
                {
                    this.props.type === 'print-hotel-credit-note' &&
                        <PrintHotelCreditNote {...this.props}/>
                }
                {
                    this.props.type === 'print-receipt-voucher' && 
                    <ReceiptVoucherComponent {...this.props}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        searchQuery: state.flightSearchReducer.searchQuery,
        accountResponse             : state.myAccount,
        calendarResponse            : state.myAccount.calendarResponse,
        productResponse             : state.shared.productResponse,
        isBookingPendingData        : state.shared.isBookingPending,
        pagesResponse               : state.shared.pagesResponse,
        ticketBookingData           : state.flightBookTicketReducer.ticketBookingData,//get response from ticket booking,
        bookingDetails              : state.myAccount.bookingDetails,//Get booking details
        settingResponse             : state.shared.settingResponse,
        updateTicketDetails         : state.myAccount.updateTicketDetails,//Update ticket
        emailTicketDetails          : state.myAccount.emailTicketDetails,//email ticket
        emailInvoiceDetails         : state.myAccount.emailInvoiceDetails,//invoice ticket
        agentProductAccess          : state.shared.agentProductAccess,
        cancelRefundDetails         : state.myAccount.cancelRefundDetails,
        emailOfflineTicketDetails   : state.myAccount.emailOfflineTicketDetails,//email offline ticket
        emailOfflineInvoiceDetails  : state.myAccount.emailOfflineInvoiceDetails,//invoice offline ticket
        cancelHotelBookingDetails   : state.myAccount.cancelHotelBookingDetails,
        voucherDetails              : state.hotelBookReducer.voucherDetails,
        hotelBookedDetails          : state.hotelBookReducer.hotelBookedDetails,
        emailFlightCreditNoteInfo   : state.myAccount.emailFlightCreditNoteInfo,//email flight credit note        
        emailHotelCreditNoteInfo    : state.myAccount.emailHotelCreditNoteInfo,//email hotel credit note
        bookingInfoResponse         : state.myAccount.bookingInfoResponse,
        releasePnrDetail            : state.myAccount.releasePnrDetail,
        releaseTripJackDetail       : state.myAccount.releaseTripJackDetail,
        tripJackBookDetailsData         : state.flightBookTicketReducer.tripJackTicketBookDetails, // get response form trip Jack Book Details api
        tripJackFlightFareRuleDetails: state.flightSearchDetailsReducer.tripJackFlightFareRuleDetails,
        tripJackRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.tripJackRoundFlightFareRuleDetails,
        airIQFlightFareRuleDetails: state.flightSearchDetailsReducer.airIQFlightFareRuleDetails,
        airIQRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.airIQRoundFlightFareRuleDetails,
        goflyFlightFareRuleDetails: state.flightSearchDetailsReducer.goflyFlightFareRuleDetails,
        goflyRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.goflyRoundFlightFareRuleDetails,
        eflyFlightFareRuleDetails: state.flightSearchDetailsReducer.eflyFlightFareRuleDetails,
        eflyRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.eflyRoundFlightFareRuleDetails,
        validateTripJackTicket:state.flightBookTicketReducer.validateTripJackTicket,
        satkarFlightFareRuleDetails: state.flightSearchDetailsReducer.satkarFlightFareRuleDetails,
        satkarRoundFlightFareRuleDetails: state.flightSearchDetailsReducer.satkarRoundFlightFareRuleDetails,
        hotelReview: state.hotelBookReducer.hotelReview,
        cancelPolicy: state.hotelSearchDetailsReducer.cancelPolicy,
        blockHotelDetails: state.hotelBookReducer.blockHotelDetails,
        riyaBookDetails : state.flightBookTicketReducer.riyaBookDetails,
        tcsDeclarationResults: state.hotelBookReducer.tcsDeclarationResults
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getCalendarDetail       : (param) => dispatch(getCalendarDetail(param)),
        myAccountAction         : () => dispatch(myAccountAction()),
        logoutAction            : () => dispatch(logoutAction()),
        getBookingDetails       : (credentials) => dispatch(getBookingDetails(credentials)),//Get booking details
        bookTicket              : (credentials,successCb,errorCb)=> dispatch(bookTicket(credentials,successCb,errorCb)),//start ticket booking
        updateTicket            : (credentials,successCb,errorCb)=> dispatch(updateTicket(credentials,successCb,errorCb)),//start ticket booking
        emailTicket             : (credentials,successCb,errorCb)=> dispatch(emailTicket(credentials,successCb,errorCb)),//start email ticket booking
        emailInvoice            : (credentials,successCb,errorCb)=> dispatch(emailInvoice(credentials,successCb,errorCb)),//start email invoice booking
        cancelRefund            : (data) => dispatch(cancelRefund(data)),
        cancelHotelRefund       : (data) => dispatch(cancelHotelRefund(data)),
        getProductAccess        : () => dispatch(getProductAccess()),
        emailOfflineTicket      : (credentials,successCb,errorCb)=> dispatch(emailOfflineTicket(credentials,successCb,errorCb)),//start email ticket booking
        emailOfflineInvoice     : (credentials,successCb,errorCb)=> dispatch(emailOfflineInvoice(credentials,successCb,errorCb)),//start email invoice booking
        generateVoucher         : (data,successCb,errorCb) => dispatch(generateVoucher(data,successCb,errorCb)),//Generate Voucher to confirm booking
        getBookingHotelDetails  : (data,successCb,errorCb) => dispatch(getBookingHotelDetails(data,successCb,errorCb)),
        emailFlightCreditNote   : (data,successCb,errorCb) => dispatch(emailFlightCreditNote(data,successCb,errorCb)),//email flight credit note
        emailHotelCreditNote    : (data,successCb,errorCb) => dispatch(emailHotelCreditNote(data,successCb,errorCb)),//email hotel credit note
        bookingInfoAction       : (data,successCb,errorCb) => dispatch(bookingInfoAction(data,successCb,errorCb)),
        releasePnr              :(data,successCb,errorCb) => dispatch(releasePnr(data,successCb,errorCb)),
        releaseTripJack         : (data, successCb, errorCb) => dispatch(releaseTripJack(data, successCb, errorCb)),
        tripJackBookConfirm     : (credentials, successCb, errorcb)=>dispatch(tripJackBookConfirm(credentials, successCb, errorcb)),
        validateTripJackTicket  : (credentials, successCb, errorcb)=>dispatch(validateTripJackTicket(credentials, successCb, errorcb)),
        tripJackBookDetails     : (credentials,successCb,errorcb) => dispatch(tripJackBookDetails(credentials, successCb, errorcb)), //Tripjack book details
        getTripJackFlightFareRuleDetails: (credential) => dispatch(getTripJackFlightFareRuleDetails(credential)),
        getAirIQFlightFareRuleDetails: (credential) => dispatch(getAirIQFlightFareRuleDetails(credential)),
        getGoflyFlightFareRuleDetails: (credential) => dispatch(getGoflyFlightFareRuleDetails(credential)),
        geteflyFlightFareRuleDetails: (credential) => dispatch(geteflyFlightFareRuleDetails(credential)),
        getSatkarFlightFareRuleDetails: (credential) => dispatch(getSatkarFlightFareRuleDetails(credential)),
        getRiyaFlightFareRuleDetails: (credential) => dispatch(getRiyaFlightFareRuleDetails(credential)),
       
        riyaBookConfirm: (credentials, successCb, errorcb) =>
            dispatch(riyaBookConfirm(credentials, successCb, errorcb)),
        generateConfirmBook: (data, successCb, errorCb) => dispatch(generateConfirmBook(data, successCb, errorCb)),//Generate tripjack Voucher to confirm booking
        getTripjackBookingHotelDetails: (data, successCb, errorCb) => dispatch(getTripjackBookingHotelDetails(data, successCb, errorCb)),
        isBookingPending: (response) => dispatch(getTripjackBookingHotelDetails(isBookingPending)),
        riyaBookDetails: (credentials, successCb, errorcb) => dispatch(riyaBookDetails(credentials, successCb, errorcb)), //riya book details
        hotelTripjackTCSDeclaration: (data, successCb, errorCb) => dispatch(hotelTripjackTCSDeclaration(data, successCb, errorCb)), //Book Tripjack hotel
    
    }
};
export default connect(mapStateToProps,mapDispatchToProps)(AgentCalendar)
